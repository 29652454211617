import {
  IconHome,
  IconSearch,
  IconLibrary,
  IconSetting,
  IconCreditCard,
  IconAnnotate,
  IconGlobe,
  IconChat,
  IconStar,
  IconDocument,
  IconCustomers as IconMembers,
  IconQr,
} from '@layerise/design-icon';
import {
  IconContainer,
  IconWarrantyRequests,
  IconAssistants,
  IconCustomers,
  IconInsights,
  IconChatClosed,
  IconChatForYou,
  IconChatInbox,
  IconChatUnassigned,
  IconApps,
  IconLibStories,
  IconLibArticles,
  IconLibAssistants,
  IconAutomationForms,
} from 'components/navigation/sidebar/icons';
import { IconMarketingSms } from '../components/navigation/sidebar/icons/IconMarketingSms';
import { IconAutomationRules } from '../components/navigation/sidebar/icons/IconAutomationRules';
import { IconLibComponents } from '../components/navigation/sidebar/icons/IconLibComponents';
import { IconLibMedia } from '../components/navigation/sidebar/icons/IconLibMedia';
import { IconLibSerialNumbers } from 'components/navigation/sidebar/icons/IconLibSerialNumbers';

export const links = (slug?: string | null) => {
  const [workspaceHref, workspaceAs] = ['/w/[id]', `/w/${slug}`];
  const getPathTuple = (path: string) => [`${workspaceHref}/${path}`, `${workspaceAs}/${path}`];

  const [chatHref, chatAs] = getPathTuple('chat');
  const [libraryHref, libraryAs] = getPathTuple('library');
  const [marketingHref, marketingAs] = getPathTuple('marketing');
  const [customersHref, customersAs] = getPathTuple('customers');
  const [insightsHref, insightsAs] = getPathTuple('insights');
  const [feedbackHref, feedbackAs] = getPathTuple('feedback');
  const [workspaceSettingsHref, workspaceSettingsAs] = getPathTuple('settings/workspace');
  const [organisationSettingsHref, organisationSettingsAs] = getPathTuple('settings/organisation');
  const [automationHref, automationAs] = getPathTuple('automation');

  return {
    home: {
      title: 'Home',
      icon: (
        <IconContainer bgColor="#062af3">
          <IconHome width="11px" />
        </IconContainer>
      ),
      href: workspaceHref,
      as: workspaceAs,
    },
    plan: {
      title: 'Setup',
      href: `${workspaceHref}/plan`,
      as: `${workspaceAs}/plan`,
      setup: {
        title: 'Setup',
        href: `${workspaceHref}/plan/setup`,
        as: `${workspaceAs}/plan/setup`,
      },
      payment: {
        title: 'Setup',
        href: `${workspaceHref}/plan/payment`,
        as: `${workspaceAs}/plan/payment`,
      },
    },
    products: {
      title: 'Products',
      icon: <IconAssistants />,
      href: `${workspaceHref}/products`,
      as: `${workspaceAs}/products`,

      product: {
        href: `${workspaceHref}/products/p/[productId]`,
        as: (productId: string) => `${workspaceAs}/products/p/${productId}`,
      },
    },
    search: {
      title: 'Search',
      icon: <IconSearch />,
      href: `${workspaceHref}/search`,
      as: `${workspaceAs}/search`,
    },
    chat: {
      title: 'Chat',
      icon: <IconChat />,
      href: `${chatHref}/inbox`,
      as: `${chatAs}/inbox`,
      listener: chatHref,
      inbox: {
        title: 'Inbox',
        icon: <IconChatInbox />,
        href: `${chatHref}/inbox`,
        as: `${chatAs}/inbox`,
      },
      forYou: {
        title: 'For you',
        icon: <IconChatForYou />,
        href: `${chatHref}/for-you`,
        as: `${chatAs}/for-you`,
      },
      unassigned: {
        title: 'Unassigned',
        icon: <IconChatUnassigned />,
        href: `${chatHref}/unassigned`,
        as: `${chatAs}/unassigned`,
      },
      closed: {
        title: 'Closed',
        icon: <IconChatClosed />,
        href: `${chatHref}/closed`,
        as: `${chatAs}/closed`,
      },
    },
    customers: {
      title: 'Customers',
      icon: <IconCustomers />,
      href: `${customersHref}/all`,
      as: `${customersAs}/all`,
      listener: customersHref,
      customers: {
        title: 'All',
        href: `${customersHref}/all`,
        as: `${customersAs}/all`,

        single: {
          href: `${customersHref}/[customerId]`,
          as: (customerId: string) => `${customersAs}/${customerId}`,
        },
      },
      review: {
        href: `${customersHref}/review/w/[warrantyRegistrationId]`,
        as: (warrantyRegistrationId: string) => `${customersAs}/review/w/${warrantyRegistrationId}`,
      },
      warranties: {
        title: 'Warranty requests',
        icon: <IconWarrantyRequests />,
        href: `${workspaceHref}/service/warranty-requests`,
        as: `${workspaceAs}/service/warranty-requests`,
      },
      lists: {
        listing: {
          title: 'Lists',
          href: `${customersHref}/lists`,
          as: `${customersAs}/lists`,
        },
        edit: {
          title: 'Customers list',
          href: `${customersHref}/lists/[listId]`,
          as: (listId: string) => `${customersAs}/lists/${listId}`,
        },
      },
    },
    insights: {
      title: 'Insights',
      icon: <IconInsights />,
      href: insightsHref,
      as: insightsAs,
      listener: insightsHref,
      reports: {
        title: 'Reports',
        href: `${insightsHref}/reports`,
        as: `${insightsAs}/reports`,
      },
      utmCampaigns: {
        title: 'UTM Campaigns',
        href: `${insightsHref}/reports/utm-campaigns`,
        as: `${insightsAs}/reports/utm-campaigns`,
      },
      dashboard: {
        title: 'Dashboard',
        href: `${insightsHref}/dashboard`,
        as: `${insightsAs}/dashboard`,
      },
    },
    feedback: {
      title: 'Feedback',
      icon: (
        <IconContainer bgColor="#062af3">
          <IconStar width="12px" />
        </IconContainer>
      ),
      href: `${feedbackHref}/product`,
      as: `${feedbackAs}/product`,
      listener: feedbackHref,
      product: {
        title: 'Products',
        href: `${feedbackHref}/product`,
        as: `${feedbackAs}/product`,

        single: {
          href: `${feedbackHref}/product/[productId]`,
          as: (productId: string) => `${feedbackAs}/product/${productId}`,
        },
      },
      article: {
        title: 'Articles',
        href: `${feedbackHref}/article`,
        as: `${feedbackAs}/article`,

        single: {
          href: `${feedbackHref}/article/[articleId]`,
          as: (articleId: string) => `${feedbackAs}/article/${articleId}`,
        },
      },
    },
    marketing: {
      distribution: {
        listing: {
          title: 'Distribution',
          href: `${marketingHref}/distribution`,
          as: `${marketingAs}/distribution`,
          icon: (
            <IconContainer bgColor="#7a52d6">
              <IconQr width="12px" />
            </IconContainer>
          ),
        },
        edit: {
          qr: {
            title: 'Distribution',
            href: `${marketingHref}/distribution/qr/[distributionId]`,
            as: (distributionId: string) => `${marketingAs}/distribution/qr/${distributionId}`,
          },
          widget: {
            title: 'Distribution',
            href: `${marketingHref}/distribution/widget/[distributionId]`,
            as: (distributionId: string) => `${marketingAs}/distribution/widget/${distributionId}`,
          },
        },
      },
      sms: {
        listing: {
          title: 'SMS',
          icon: <IconMarketingSms />,
          href: `${marketingHref}/sms`,
          as: `${marketingAs}/sms`,
        },
        create: {
          title: 'SMS',
          href: `${marketingHref}/sms/create`,
          as: `${marketingAs}/sms/create`,
        },
        edit: {
          title: 'SMS',
          href: `${marketingHref}/sms/[campaignId]`,
          as: `${marketingAs}/sms/[campaignId]`,
        },
      },
    },
    library: {
      title: 'Library',
      href: `${libraryHref}/media`,
      as: `${libraryAs}/media`,
      listener: libraryHref,
      icon: <IconLibrary />,
      media: {
        title: 'Media',
        icon: <IconLibMedia />,
        href: `${libraryHref}/media`,
        as: `${libraryAs}/media`,

        file: {
          href: `${libraryHref}/media/f/[file]`,
          as: (file: string) => `${libraryAs}/media/f/${file}`,
        },
      },
      components: {
        title: 'Components',
        icon: <IconLibComponents />,
        href: `${libraryHref}/components`,
        as: `${libraryAs}/components`,

        component: {
          href: `${libraryHref}/components/[componentId]`,
          as: (componentId: string) => `${libraryAs}/components/${componentId}`,
        },
      },
      stories: {
        title: 'Stories',
        icon: <IconLibStories />,
        href: `${libraryHref}/stories`,
        as: `${libraryAs}/stories`,
        story: {
          href: `${libraryHref}/stories/s/[storyId]`,
          as: (storyId: string) => `${libraryAs}/stories/s/${storyId}`,
        },
      },
      articles: {
        title: 'Articles',
        icon: <IconLibArticles />,
        href: `${libraryHref}/articles`,
        as: `${libraryAs}/articles`,
        single: {
          href: `${libraryHref}/articles/[articleId]`,
          as: (articleId: string) => `${libraryAs}/articles/${articleId}`,
        },
      },
      warranties: {
        title: 'Warranties',
        icon: (
          <IconContainer bgColor="#fe871a">
            <IconDocument width="12px" />
          </IconContainer>
        ),
        href: `${libraryHref}/warranties`,
        as: `${libraryAs}/warranties`,

        single: {
          href: `${libraryHref}/warranties/[warrantyId]`,
          as: (warrantyId: string) => `${libraryAs}/warranties/${warrantyId}`,
        },
      },
      assistants: {
        title: 'Assistants',
        icon: <IconLibAssistants />,
        href: `${libraryHref}/assistants`,
        as: `${libraryAs}/assistants`,
        assistant: {
          href: `${libraryHref}/assistants/[assistantId]/[locale]`,
          as: (assistantId: string, locale: string) => `${libraryAs}/assistants/${assistantId}/${locale}`,
        },
      },
      serialNumbers: {
        title: 'Serial numbers',
        icon: <IconLibSerialNumbers />,
        href: `${libraryHref}/serial-numbers`,
        as: `${libraryAs}/serial-numbers`,
        single: {
          href: `${libraryHref}/serial-numbers/[serialNumberId]/`,
          as: (id: string) => `${libraryAs}/serial-numbers/${id}/`,
        },
      },
    },
    automation: {
      rules: {
        listing: {
          title: 'Rules',
          href: `${automationHref}/rules`,
          as: `${automationAs}/rules`,
          icon: <IconAutomationRules />,
        },
        edit: {
          title: 'Rule',
          href: `${automationHref}/rules/[ruleId]`,
          as: (ruleId: string) => `${automationAs}/rules/${ruleId}`,
        },
      },
      forms: {
        listing: {
          title: 'Forms',
          href: `${automationHref}/forms`,
          as: `${automationAs}/forms`,
          icon: <IconAutomationForms />,
        },
        edit: {
          title: 'Form',
          href: `${automationHref}/forms/[formId]`,
          as: (formId: string) => `${automationAs}/forms/${formId}`,
        },
      },
    },
    settings: {
      title: 'Settings',
      href: workspaceSettingsHref,
      as: workspaceSettingsAs,
      icon: (
        <IconContainer bgColor="#474666">
          <IconSetting width="12px" />
        </IconContainer>
      ),
      organisation: {
        title: 'Organisation settings',
        href: organisationSettingsHref,
        as: organisationSettingsAs,
        plan: {
          title: 'Billing',
          altTitle: 'Plan',
          href: `${organisationSettingsHref}/plan`,
          as: `${organisationSettingsAs}/plan`,
          icon: <IconCreditCard />,
        },
        usage: {
          title: 'Usage',
          href: `${organisationSettingsHref}/usage`,
          as: `${organisationSettingsAs}/usage`,
        },
        payments: {
          title: 'Payments',
          href: `${organisationSettingsHref}/payments`,
          as: `${organisationSettingsAs}/payments`,
        },
        members: {
          title: 'Admins & members',
          href: `${organisationSettingsHref}/users`,
          as: `${organisationSettingsAs}/users`,
        },
        data: {
          title: 'Data management',
          href: `${organisationSettingsHref}/data`,
          as: `${organisationSettingsAs}/data`,
        },
        advanced: {
          title: 'Advanced',
          href: `${organisationSettingsHref}/advanced`,
          as: `${organisationSettingsAs}/advanced`,
        },
        workspaces: {
          create: {
            title: 'Create workspace',
            href: `${organisationSettingsHref}/workspaces/create`,
            as: `${organisationSettingsAs}/workspaces/create`,
          },
        },
      },
      workspace: {
        title: 'Workspace settings',
        href: `${workspaceSettingsHref}`,
        as: `${workspaceSettingsAs}`,
        icon: <IconSetting />,
        legal: {
          title: 'Legal',
          href: `${workspaceSettingsHref}/legal`,
          as: `${workspaceSettingsAs}/legal`,
          icon: <IconDocument />,
        },
        branding: {
          title: 'Branding',
          href: `${workspaceSettingsHref}/branding`,
          as: `${workspaceSettingsAs}/branding`,
          icon: <IconAnnotate />,
        },
        chat: {
          title: 'Chat',
          href: `${workspaceSettingsHref}/chat`,
          as: `${workspaceSettingsAs}/chat`,
        },
        domain: {
          title: 'Domain',
          href: `${workspaceSettingsHref}/domain`,
          as: `${workspaceSettingsAs}/domain`,
          icon: <IconGlobe />,
        },
        members: {
          title: 'Members & permissions',
          href: `${workspaceSettingsHref}/users`,
          as: `${workspaceSettingsAs}/users`,
          icon: <IconMembers />,
        },
        sms: {
          title: 'SMS',
          href: `${workspaceSettingsHref}/sms`,
          as: `${workspaceSettingsAs}/sms`,
        },
        teams: {
          title: 'Teams',
          href: `${workspaceSettingsHref}/teams`,
          as: `${workspaceSettingsAs}/teams`,
          icon: <IconMembers />,
        },
        tags: {
          href: `${workspaceSettingsHref}/tags`,
          as: `${workspaceSettingsAs}/tags`,
        },
        developer: {
          href: `${workspaceSettingsHref}/developer`,
          as: `${workspaceSettingsAs}/developer`,
        },
        advanced: {
          href: `${workspaceSettingsHref}/advanced`,
          as: `${workspaceSettingsAs}/advanced`,
        },
        apps: {
          title: 'Apps',
          icon: <IconApps />,
          href: `${workspaceSettingsHref}/apps`,
          as: `${workspaceSettingsAs}/apps`,

          all: {
            href: `${workspaceSettingsHref}/apps/all`,
            as: `${workspaceSettingsAs}/apps/all`,
          },
          appSlug: {
            href: `${workspaceSettingsHref}/apps/[appSlug]`,
            as: (appSlug: string) => `${workspaceSettingsAs}/apps/${appSlug}`,
          },
          category: {
            href: `${workspaceSettingsHref}/apps/c/[categorySlug]`,
            as: (categorySlug: string) => `${workspaceSettingsAs}/apps/c/${categorySlug}`,
          },
          index: {
            href: `${workspaceSettingsHref}/apps`,
            as: `${workspaceSettingsAs}/apps`,
          },
          install: {
            href: `${workspaceSettingsHref}/apps/install/[appSlug]`,
            as: (appSlug: string) => `${workspaceSettingsAs}/apps/install/${appSlug}`,
          },
          installed: {
            href: `${workspaceSettingsHref}/apps/installed/[appSlug]`,
            as: (appSlug: string) => `${workspaceSettingsAs}/apps/installed/${appSlug}`,
          },
          recommended: {
            href: `${workspaceSettingsHref}/apps/recommended`,
            as: `${workspaceSettingsAs}/apps/recommended`,
          },
        },
      },
    },
  };
};
