import { fromUnixTime, differenceInCalendarDays } from 'date-fns';
import { PlanFeature, CurrentMemberQuery, MemberType } from 'types/typed-document-nodes';

export enum StripeStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

export const getPlanData = ({
  planLimit,
  planFeatures,
}: {
  planLimit: NonNullable<NonNullable<CurrentMemberQuery['me']>['organisation']>['plan'];
  planFeatures: PlanFeature | undefined;
}) => {
  const name = planLimit?.name;
  const { currency, interval } = planFeatures;
  const status = planFeatures.status as StripeStatus;
  const trialStart = planFeatures.trialStart && fromUnixTime(planFeatures.trialStart);
  const trialEnd = planFeatures.trialEnd && fromUnixTime(planFeatures.trialEnd);
  const trialDaysLeft = trialEnd && differenceInCalendarDays(trialEnd, new Date());

  return {
    planLimit,
    currency,
    name,
    interval: interval === 'month' ? 'monthly' : 'yearly',
    status: {
      active: [StripeStatus.ACTIVE, StripeStatus.PAST_DUE, StripeStatus.TRIALING].includes(status),
      trial: status === StripeStatus.TRIALING ? trialDaysLeft : false,
      cancelled: status === StripeStatus.CANCELED,
      notPaid: [StripeStatus.PAST_DUE, StripeStatus.INCOMPLETE, StripeStatus.INCOMPLETE_EXPIRED].includes(status),
    },
    trial: {
      start: trialStart,
      end: trialEnd,
    },
  };
};

export enum MemberPermissionGroup {
  Standard = 'STANDARD',
  Lite = 'LITE',
}

export const SeatLabelByType: Record<MemberType, string> = {
  [MemberType.Standard]: 'Standard user',
  [MemberType.LiteContentMarketer]: 'Content Marketer',
  [MemberType.LiteCustomerSupport]: 'Customer Support Agent',
};

export function getPermissionGroupByType(memberType: MemberType) {
  switch (memberType) {
    case MemberType.Standard:
      return MemberPermissionGroup.Standard;
    case MemberType.LiteContentMarketer:
    case MemberType.LiteCustomerSupport:
      return MemberPermissionGroup.Lite;
  }
}

export function getPermissionGroupLabel(group: MemberPermissionGroup) {
  switch (group) {
    case MemberPermissionGroup.Standard:
      return 'Standard';
    case MemberPermissionGroup.Lite:
      return 'Lite';
  }
}
