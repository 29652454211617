import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 7px;
  padding-top: 24px;
  padding-bottom: 24px;
  background: #fff;
  padding-bottom: 40px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 1px 1px 0px rgba(0, 0, 0, 0.12),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 2px 5px 0px rgba(60, 66, 87, 0.08);
  svg {
    height: 76px;
  }
`;
const Title = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 8px;
`;
const Description = styled.p`
  color: #140038;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 16px;
  margin-top: 4px;
  margin-bottom: 8px;
`;
const Action = styled.a`
  font-weight: 500;
  color: #052bfc;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 12px;
  cursor: pointer;
`;

interface EmptyTableProps {
  title?: string;
  description?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  action?: ReactNode;
  illustration?: React.ReactElement;
  className?: string;
}

export const EmptyTable: React.FC<EmptyTableProps> = ({
  title,
  description,
  onClick,
  action,
  illustration,
  className,
}) => {
  return (
    <Container className={className}>
      {illustration}
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {onClick && <Action onClick={onClick}>{action}</Action>}
    </Container>
  );
};
