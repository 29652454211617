import { useContext, useEffect, useMemo, useState } from 'react';
import { Locale } from 'types/typed-document-nodes';
import { InfoTag } from 'components/table/InfoTags';
import { LocaleConnectionListItem } from './ConnectionListItem';
import { Container } from './styles';
import { LanguageConnectionHeader } from './ConnectionHeader';
import { SideDrawerContext } from 'contexts/SideDrawerContext';
import { CONNECTION_BUTTON_CLASS_NAME } from './ConnectionsButton';

type Props = {
  locales: Pick<Locale, 'id' | 'nameEN'>[] | undefined;
  entityName: string;
};

export function LocaleConnectionsTag({ locales, entityName }: Props) {
  const { openDrawer, updateContent, animationFinished } = useContext(SideDrawerContext);
  const [opened, setOpened] = useState(false);
  const sortedLocales = useMemo(() => {
    return [...(locales ?? [])].sort((a, b) => a.nameEN.localeCompare(b.nameEN));
  }, [locales]);

  const content = useMemo(
    () => (
      <Container>
        <LanguageConnectionHeader
          entityName={entityName}
          animationFinished={animationFinished}
          total={locales?.length}
        />
        {sortedLocales.map(l => (
          <LocaleConnectionListItem key={l.id} name={l.nameEN} />
        ))}
      </Container>
    ),
    [entityName, sortedLocales, locales, animationFinished]
  );

  useEffect(() => {
    if (opened) {
      updateContent({ children: content });
    }
  }, [opened, updateContent, content]);

  useEffect(() => {
    if (!animationFinished) {
      setOpened(false);
    }
  }, [animationFinished]);

  return (
    <InfoTag
      className={CONNECTION_BUTTON_CLASS_NAME}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        openDrawer({
          title: 'Languages',
          children: content,
        });
        setOpened(true);
      }}>
      {locales?.length ?? 0}
    </InfoTag>
  );
}
