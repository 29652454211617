import styled from 'styled-components';
import { Button, Input, useForm, ModalActions, Modal, PasswordHint } from '@layerise/design-core';
import { useMutation } from '@apollo/client';
import { ChangePasswordMutationDocument } from 'types/typed-document-nodes';
import { useApiErrorHandler } from 'hooks/useApiErrorHandler';
import { useContext, useState } from 'react';
import ToastContext from 'contexts/ToastContext';

const FormGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type Props = {
  onRequestClose(): void;
  onSuccess?(): void;
  zIndex?: number;
};

export const ChangePasswordModal = ({ onSuccess, onRequestClose, zIndex }: Props) => {
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);
  const { setToast } = useContext(ToastContext);
  const { inputs, handleChange } = useForm({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [changePassword, { loading }] = useMutation(ChangePasswordMutationDocument, {
    variables: {
      currentPassword: inputs.currentPassword,
      newPassword: inputs.newPassword,
      confirmNewPassword: inputs.confirmNewPassword,
    },
    onError: useApiErrorHandler(),
  });

  return (
    <Modal
      modalContainerStyle={{ zIndex }}
      containerStyle={{ zIndex: typeof zIndex === 'undefined' ? undefined : zIndex + 1 }}
      header="Change password"
      onClick={onRequestClose}>
      <FormGrid>
        <Input
          type="password"
          label="Current password"
          value={inputs.currentPassword}
          onChange={handleChange}
          required
          name="currentPassword"
          placeholder="Current password"
          autoComplete="current-password"
        />
        <PasswordHint password={inputs.newPassword} isFocused={isPasswordInputFocused}>
          <Input
            type="password"
            label="New password"
            value={inputs.newPassword}
            onChange={handleChange}
            required
            name="newPassword"
            placeholder="New password"
            onFocus={() => setIsPasswordInputFocused(true)}
            onBlur={() => setIsPasswordInputFocused(false)}
            autoComplete="new-password"
          />
        </PasswordHint>
        <Input
          type="password"
          label="Repeat new password"
          value={inputs.confirmNewPassword}
          onChange={handleChange}
          required
          name="confirmNewPassword"
          placeholder="Repeat new password"
          autoComplete="new-password"
        />
      </FormGrid>

      <ModalActions>
        <Button size="lg" type="button" onClick={onRequestClose}>
          Cancel
        </Button>
        <Button
          size="lg"
          variant="primary"
          onClick={async () => {
            const validateInput = inputs.currentPassword && inputs.newPassword && inputs.confirmNewPassword;
            if (!validateInput) {
              setToast({
                type: 'warning',
                message: 'Please fill out all fields',
              });
              return;
            }
            const { errors } = await changePassword();
            if (!errors) {
              onSuccess?.();
              onRequestClose();
            }
          }}
          disabled={loading}
          loading={loading}
          type="submit">
          Change
        </Button>
      </ModalActions>
    </Modal>
  );
};
