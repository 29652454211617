import styled from 'styled-components';
import { useForm, Card, Button, Toggle } from '@layerise/design-core';
import { Title } from 'components/settings/elements';
import { Actions as ActionsBase } from 'components/settings/workspace/elements';
import { Heading } from 'components/settings/elements';
import { EditMemberFragmentFragment, UpdateMemberNotificationDocument } from 'types/typed-document-nodes';
import ToastContext from 'contexts/ToastContext';
import { useContext, useEffect } from 'react';
import { FieldDescription, FieldTitle, Item, Save } from '../elements';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { useApiErrorHandler } from 'hooks/useApiErrorHandler';

const Actions = styled(ActionsBase)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
`;

type Props = {
  member: EditMemberFragmentFragment;
  readonly: boolean;
  refetch(): Promise<ApolloQueryResult<unknown>[]> | Promise<ApolloQueryResult<unknown>>;
};

export function NotificationsView({ member, readonly, refetch }: Props) {
  const { setToast } = useContext(ToastContext);

  const { notification } = member;
  const chatNotification = !!notification?.chatNotification;
  const emailPromotion = !!notification?.emailPromotion;
  const weeklyDigest = !!notification?.weeklyDigest;

  const [notificationMutation, { loading }] = useMutation(UpdateMemberNotificationDocument, {
    onError: useApiErrorHandler(),
    onCompleted: async () => {
      await refetch();
      setToast({
        type: 'success',
        message: 'Member notifications preferences successfully updated',
      });
    },
  });

  const { inputs, setInputs, handleChange } = useForm({
    emailPromotion,
    chatNotification,
    weeklyDigest,
  });

  useEffect(() => {
    setInputs({
      emailPromotion: !!notification?.emailPromotion,
      chatNotification: !!notification?.chatNotification,
      weeklyDigest: !!notification?.weeklyDigest,
    });
  }, [notification]);

  const isChanged =
    inputs.emailPromotion !== emailPromotion ||
    inputs.weeklyDigest !== weeklyDigest ||
    inputs.chatNotification !== chatNotification;

  return (
    <section>
      <Heading>Notifications</Heading>
      <Title>Updates</Title>
      <Card none>
        <form
          onSubmit={async e => {
            e.preventDefault();
            if (!notification) return;
            await notificationMutation({
              variables: {
                id: notification.id,
                weeklyDigest: inputs.weeklyDigest,
                emailPromotion: inputs.emailPromotion,
                chatNotification: inputs.chatNotification,
              },
            });
          }}>
          <Item>
            <div>
              <FieldTitle>Product updates</FieldTitle>
              <FieldDescription>Receive important product updates</FieldDescription>
            </div>
            <Actions>
              <Toggle name="emailPromotion" value={inputs.emailPromotion} onChange={handleChange} />
            </Actions>
          </Item>
          <Item>
            <div>
              <FieldTitle>Chat & Warranty notifications</FieldTitle>
              <FieldDescription>Receive new chat and warranty updates</FieldDescription>
            </div>
            <Actions>
              <Toggle name="chatNotification" value={inputs.chatNotification} onChange={handleChange} />
            </Actions>
          </Item>
          <Item>
            <div>
              <FieldTitle>Weekly digest</FieldTitle>
              <FieldDescription>Receive weekly digest</FieldDescription>
            </div>
            <Actions>
              <Toggle name="weeklyDigest" value={inputs.weeklyDigest} onChange={handleChange} />
            </Actions>
          </Item>
          <Save>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              loading={loading}
              restricted={readonly}
              disabled={!isChanged || loading}>
              {loading ? 'Saving...' : 'Save changes'}
            </Button>
          </Save>
        </form>
      </Card>
    </section>
  );
}
