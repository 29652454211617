import { useContext, Fragment, useState, useEffect, useMemo } from 'react';
import { InfoTag } from 'components/table/InfoTags';
import { CountryConnectionListItem, CountryContinentListItem } from './ConnectionListItem';
import { Container } from './styles';
import { CountryConnectionHeader } from './ConnectionHeader';
import { SideDrawerContext } from 'contexts/SideDrawerContext';
import { CONNECTION_BUTTON_CLASS_NAME } from './ConnectionsButton';

const CONTINENT_SEQUENCE = ['Europe', 'North America', 'Oceania', 'Asia', 'Africa', 'South America', 'Antarctica'];
const COLLAPSED_CONTINENT_GROUPS_INITIAL_STATE = CONTINENT_SEQUENCE.reduce((acc, c) => {
  acc[c] = false;
  return acc;
}, {} as Record<string, boolean>);

type Props = {
  entityName: string;
  countries:
    | {
        id: string;
        nameEN: string;
        continent: {
          name: string;
        };
      }[]
    | undefined;
};

export function CountryConnectionsTag({ countries, entityName }: Props) {
  const { openDrawer, updateContent, animationFinished } = useContext(SideDrawerContext);
  const [opened, setOpened] = useState(false);

  const [collapsedGroupsState, setCollapsedGroupsState] = useState(COLLAPSED_CONTINENT_GROUPS_INITIAL_STATE);

  const groupedCountries = useMemo(() => {
    return (countries ?? [])?.reduce((acc, c) => {
      acc[c.continent.name] ||= [];
      acc[c.continent.name].push(c);
      return acc;
    }, {} as Record<string, NonNullable<typeof countries>>);
  }, [countries]);

  const content = useMemo(
    () => (
      <Container>
        <CountryConnectionHeader
          entityName={entityName}
          animationFinished={animationFinished}
          total={countries?.length}
        />
        {CONTINENT_SEQUENCE.map(continent => {
          if (!groupedCountries[continent]?.length) return null;
          const isGroupCollapsed = Boolean(collapsedGroupsState[continent]);
          return (
            <Fragment key={continent}>
              <CountryContinentListItem
                name={continent}
                onClick={() => setCollapsedGroupsState(prev => ({ ...prev, [continent]: !prev[continent] }))}
                collapsed={isGroupCollapsed}
              />
              {isGroupCollapsed
                ? null
                : groupedCountries[continent]
                    .sort((a, b) => a.nameEN.localeCompare(b.nameEN))
                    .map(c => <CountryConnectionListItem key={c.id} name={c.nameEN} />)}
            </Fragment>
          );
        })}
      </Container>
    ),
    [entityName, countries, groupedCountries, collapsedGroupsState, animationFinished]
  );

  useEffect(() => {
    if (opened) {
      updateContent({ children: content });
    }
  }, [opened, updateContent, content]);

  useEffect(() => {
    if (!animationFinished) {
      setOpened(false);
    }
  }, [animationFinished]);

  return (
    <InfoTag
      className={CONNECTION_BUTTON_CLASS_NAME}
      onClick={e => {
        e.stopPropagation();
        setCollapsedGroupsState(COLLAPSED_CONTINENT_GROUPS_INITIAL_STATE);
        openDrawer({
          title: 'Countries',
          children: content,
        });
        setOpened(true);
      }}>
      {countries?.length ?? 0}
    </InfoTag>
  );
}
