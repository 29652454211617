import { Dropdown as DropdownBase, ModalContext, useForm } from '@layerise/design-core';
import styled from 'styled-components';
import { Button, Card } from '@layerise/design-core';
import { Heading, Title } from 'components/settings/elements';
import { Save, Item, FieldTitle } from '../elements';
import { Actions } from 'components/settings/workspace/elements';
import { PermissionOptions } from './PermissionOptions';
import {
  AccessLevel,
  EditMemberFragmentFragment,
  MemberType,
  UpdateMemberPermissionsDocument,
} from 'types/typed-document-nodes';
import { MemberTypePermissionsPreset, PlatformArea, memberTypeAreaPermissions } from './presets';
import { ChangeEvent, useContext, useMemo } from 'react';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { getPermissionGroupByType, MemberPermissionGroup, SeatLabelByType } from 'lib/organisation';
import { Upsell } from 'components/featureModals';
import Link from 'next/link';
import useLinks from 'hooks/useLinks';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { useApiErrorHandler } from 'hooks/useApiErrorHandler';
import { PermissionItem } from './PermissionItem';

const Dropdown = styled(DropdownBase)``;

const PermissionsList = styled.ul`
  list-style: none;
  margin-top: 16px !important;
`;

type FormData = { type: MemberType } & Record<PlatformArea, AccessLevel>;

type Props = {
  member: EditMemberFragmentFragment;
  readonly: boolean;
  refetch(): Promise<ApolloQueryResult<unknown>[]> | Promise<ApolloQueryResult<unknown>>;
};

export function PermissionsView({ member, readonly, refetch }: Props) {
  const { workspace, organisation } = useContext(OrganisationContext);
  const { showModal } = useContext(ModalContext);
  const links = useLinks();
  const initialSeat = member.type;
  const defaultAreaPermissions = memberTypeAreaPermissions(initialSeat);
  const memberWorkspacePermissions = member.workspacePermissions.find(wp => wp.workspaceId === workspace.id);
  const accessLevel = memberWorkspacePermissions?.accessLevel;
  const planIncludesLiteUsers = Boolean(organisation.plan?.liteUserSeats);

  const defaultPermissions = useMemo(() => {
    return {
      library: accessLevel?.library ?? defaultAreaPermissions('library'),
      conversations: accessLevel?.conversations ?? defaultAreaPermissions('conversations'),
      service: accessLevel?.service ?? defaultAreaPermissions('service'),
      workspaceSettings: accessLevel?.workspaceSettings ?? defaultAreaPermissions('workspaceSettings'),
      marketing: accessLevel?.marketing ?? defaultAreaPermissions('marketing'),
      automation: accessLevel?.automation ?? defaultAreaPermissions('automation'),
      customers: accessLevel?.customers ?? defaultAreaPermissions('customers'),
    };
  }, [accessLevel, defaultAreaPermissions]);

  const { inputs, setInputs, handleChange } = useForm<FormData>({
    type: initialSeat,
    ...defaultPermissions,
  });

  const permissions = {
    library: inputs.library,
    conversations: inputs.conversations,
    service: inputs.service,
    workspaceSettings: inputs.workspaceSettings,
    marketing: inputs.marketing,
    automation: inputs.automation,
    customers: inputs.customers,
  };

  const [updatePermissions, { loading }] = useMutation(UpdateMemberPermissionsDocument, {
    variables: {
      id: member.id,
      workspaceId: workspace.id,
      permissions,
      type: inputs.type,
    },
    onError: useApiErrorHandler(),
  });
  const isChanged = inputs.type !== initialSeat || JSON.stringify(permissions) !== JSON.stringify(defaultPermissions);
  const seatsLeftNotice = '';

  return (
    <section>
      <Heading>Permissions</Heading>
      <Title>Member seat</Title>
      <Card none>
        <form
          onSubmit={async e => {
            e.preventDefault();
            await updatePermissions();
            refetch();
          }}>
          <Item>
            <FieldTitle>Select seat</FieldTitle>
            <Actions>
              {!!seatsLeftNotice && (
                <Link
                  target="_blank"
                  href={links.settings.organisation.usage.href}
                  as={links.settings.organisation.usage.as}>
                  {seatsLeftNotice}
                </Link>
              )}
              <Dropdown
                name="type"
                value={inputs.type}
                disabled={readonly}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  const selectedOption = e.target.selectedOptions?.[0];
                  const value = selectedOption?.value as MemberType;
                  handleChange(e);

                  if (
                    selectedOption?.dataset.disabled === 'true' &&
                    getPermissionGroupByType(value) === MemberPermissionGroup.Lite
                  ) {
                    showModal(Upsell, { variant: 'lite_users' });
                    setTimeout(() => {
                      setInputs({ type: MemberType.Standard });
                    }, 0);
                    return;
                  }

                  setInputs(
                    Object.entries(MemberTypePermissionsPreset[value]).reduce((acc, [k, v]) => {
                      acc[k as PlatformArea] = v.accessLevel;
                      return acc;
                    }, {} as Record<PlatformArea, AccessLevel>)
                  );
                }}>
                <option value={MemberType.Standard}>{SeatLabelByType[MemberType.Standard]}</option>
                <option value={MemberType.LiteContentMarketer} data-disabled={!planIncludesLiteUsers}>{`${
                  SeatLabelByType[MemberType.LiteContentMarketer]
                } (Lite seat)`}</option>
                <option value={MemberType.LiteCustomerSupport} data-disabled={!planIncludesLiteUsers}>{`${
                  SeatLabelByType[MemberType.LiteCustomerSupport]
                } (Lite seat)`}</option>
              </Dropdown>
            </Actions>
          </Item>
          <Item>
            <div>
              <FieldTitle>Permissions</FieldTitle>

              <PermissionsList>
                {PermissionOptions.map(option => {
                  const disabledOption = !MemberTypePermissionsPreset[inputs.type][option.key].editable;
                  return (
                    <li key={option.key}>
                      <PermissionItem
                        disabled={disabledOption}
                        readonly={readonly}
                        option={option}
                        value={inputs[option.key]}
                        handleChange={handleChange}
                      />
                    </li>
                  );
                })}
              </PermissionsList>
            </div>
          </Item>
          <Save>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              loading={loading}
              restricted={readonly}
              disabled={!isChanged || loading}>
              {loading ? 'Saving...' : 'Save changes'}
            </Button>
          </Save>
        </form>
      </Card>
    </section>
  );
}
