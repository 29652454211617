import styled, { css } from 'styled-components';
import { breakpoint } from '@layerise/design-core';

export const StyledLink = styled.a<{ activeLink?: boolean }>`
  line-height: 16px;
  letter-spacing: -0.1px;
  border-radius: 7px;
  padding: 5px 8px;
  margin: 2px 0;
  font-weight: 600;
  white-space: nowrap;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: var(--color-N600);
  cursor: pointer;

  &:hover {
    background-color: #e6e9ff;
    color: var(--color-B500);
    > div {
      background-color: var(--color-B500);
    }
  }

  ${props =>
    props.activeLink &&
    css`
      background-color: #e6e9ff;
      color: var(--color-B500);
      > div {
        background-color: var(--color-B500);
      }
    `}
`;

export const IconContainer = styled.div<{ active?: boolean }>`
  width: 18px;
  min-width: 18px;
  height: 18px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background-color: #474666;
  fill: #ffffff;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const Title = styled.p<{ fontSize?: number }>`
  font-weight: 600;
  font-size: ${props => props.fontSize || 16}px;
  color: var(--color-N600);
  letter-spacing: 0.07px;
  line-height: ${props => props.fontSize || 14}px;
  margin: 32px 0 16px;
`;

export const Heading = styled.h1<{ marginBottom?: number }>`
  font-weight: 600;
  color: var(--color-info);
  font-size: 28px;
  margin-bottom: ${props => props.marginBottom || 32}px;
`;

export const Info = styled.div<{ $wide?: boolean }>`
  ${props =>
    !props.$wide &&
    css`
      max-width: 600px;
    `}

  span {
    display: block;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-N600);
    letter-spacing: 0.06px;
    line-height: 20px;
    opacity: 0.74;
    margin-top: 4px;
  }
`;

export const SideBar = styled.div`
  height: 100%;
  background-color: var(--color-light-bg);
`;

export const Save = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px;
  border-top: 1px solid #e4e4e9;
`;

export const SettingsGrid = styled.div<{ innerLayout?: boolean }>`
  padding: ${props => (props.innerLayout ? '0px' : '32px')};
  ${props =>
    props.innerLayout &&
    css`
      height: 100%;
    `}
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NavList = styled.div`
  padding: 16px 8px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Item = styled.div<{ alignItems?: string; noBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  gap: 24px;
  border-bottom: ${props => (props.noBorder ? 'none' : '1px solid #e4e4e9')};
  padding: 18px;

  &:last-child {
    border-bottom: none;
  }
  ${breakpoint.md`
    display: grid;
    align-items: ${props => props.alignItems || 'center'};
  `}
  ${breakpoint.lg`
     grid-template-columns: auto 312px;
  `}
`;

export const Description = styled.p<{ fontStyle?: string }>`
  color: rgba(1, 0, 50, 0.74);
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 1.4;
  margin-bottom: 16px;
  font-style: ${props => props.fontStyle};
  a {
    text-decoration: underline;
  }
  ul {
    list-style-type: none;
    li {
      padding-left: 15px;
      position: relative;
      &:before {
        content: '·';
        font-size: 30px;
        vertical-align: middle;
        line-height: 15px;
        position: absolute;
        left: 0;
      }
    }
  }
`;

export const PDFFile = styled.div`
  max-width: 312px;
  height: 32px;
  display: block;
  background-color: #e3e8ee;
  overflow: hidden;
  border-radius: 5px;
  color: #140038;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 1.4;
  position: relative;
  padding: 6px 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 22px;

  svg {
    cursor: pointer;
    opacity: 0.5;
    position: absolute;
    right: 6px;
    top: 10px;
  }
`;

export const DangerActionLink = styled.a`
  cursor: pointer;
  font-size: 14px;
  color: var(--color-R500);
  font-weight: 600;
`;
