import React, { MouseEventHandler, useState } from 'react';
import { Skeleton } from '@layerise/design-core';
import { IconChevronDown } from '@layerise/design-icon';
import styled, { css } from 'styled-components';
import { m, AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import useLinks from 'hooks/useLinks';
import { StatusTag } from 'components/tag/StatusTag';
import { ContentItemStatus } from 'types/typed-document-nodes';
import { NewTabLinkButton } from 'components/navigation/NewTabLinkButton';

export enum ConnectionItemType {
  Assistant,
  Product,
}

interface ConnectionItemProps {
  type?: ConnectionItemType;
  name?: string;
  tag?: string;
  loading?: boolean;
  id?: string;
  active?: boolean;
  languageId?: string;
}

const Item = styled(m.div)<{
  hover?: boolean;
  loading?: boolean;
}>`
  display: grid;
  grid-template-columns: ${props => (props.loading ? '32px 158px 50px' : '32px 1fr auto')};
  align-items: center;
  padding: 4px 12px 4px 0;
  background-color: ${props => (props.hover ? '#f7f7fe' : '#fff')};
  gap: 8px;
  border-bottom: 1px solid #ededf3;
  overflow: hidden;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: var(--radius-large);
    border-bottom-right-radius: var(--radius-large);
  }
`;

const LanguageItem = styled(Item)`
  display: block;
  padding: 12px;
  cursor: default;
`;

const AssistantItem = styled(Item)`
  grid-template-columns: auto 1fr auto;
  padding-left: 14px;
  padding-right: 14px;
  height: 42px;
`;

const ContentStatusWrapper = styled(m.div)`
  margin-right: 5px;
`;

const Reference = styled(m.div)`
  font-weight: 500;
  color: rgba(20, 0, 56, 0.54);
  font-size: 11px;
  letter-spacing: 0em;
  line-height: 1.2;
  text-align: right;
`;

const CountryContinentItem = styled(Item)<{ $collapsed?: boolean }>`
  grid-template-columns: 1fr auto;
  padding: 12px;
  cursor: default;
  svg {
    width: 10px;
    height: 10px;
    path {
      fill: rgb(137, 127, 155);
    }
    ${props =>
      props.$collapsed &&
      css`
        transform: rotate(90deg);
      `}
  }
`;

const ConnectionItemName = styled(m.div)`
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  color: #140068;
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: left;
  flex: 3;
  margin-right: 8px;

  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  div {
    max-width: 165px;
  }
`;
const ConnectionItemReference = styled(m.div)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 499;
  color: rgba(20, 0, 56, 0.54);
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 1.2;
  flex: 1;
`;

const IconContainer = styled(m.div)`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Actions = styled(motion.div)`
  display: flex;
  gap: 12px;
`;

export const ConnectionListItem = ({ type, name, tag, loading, id, languageId, active }: ConnectionItemProps) => {
  const [hover, setHover] = useState(false);
  const links = useLinks();
  const transition = { ease: 'easeOut', duration: 0.2 };

  const href =
    type === ConnectionItemType.Assistant ? links.library.assistants.assistant.href : links.products.product.href;
  const as =
    type === ConnectionItemType.Assistant
      ? id && languageId
        ? links.library.assistants.assistant.as(id, languageId)
        : '#'
      : id
      ? links.products.product.as(id)
      : '#';

  const placeholderMargins = '0 8px 0 8px';

  return (
    <Link href={href} as={as} legacyBehavior>
      <Item
        hover={hover}
        onClick={() => setHover(!hover)}
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
        loading={loading}>
        {loading && (
          <>
            <Skeleton margin={placeholderMargins} />
            <Skeleton margin={placeholderMargins} />
            <Skeleton margin={placeholderMargins} />
          </>
        )}
        {!loading && (
          <>
            <IconContainer animate={{ x: hover ? -20 : 0, opacity: hover ? 0 : 1 }} transition={transition}>
              <StatusTag status={active ? ContentItemStatus.Active : ContentItemStatus.Draft} size="sm" />
            </IconContainer>
            <m.div animate={{ x: hover ? -20 : 0 }} transition={transition}>
              <ConnectionItemName>
                <div>{name}</div>
              </ConnectionItemName>
            </m.div>
            <AnimatePresence>
              {!hover && <ConnectionItemReference animate={{ opacity: hover ? 0 : 1 }}>{tag}</ConnectionItemReference>}
              {hover && (
                <Actions
                  exit={{ opacity: 0, x: 32 }}
                  initial={{ x: 32, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={transition}>
                  <NewTabLinkButton href={as} />
                </Actions>
              )}
            </AnimatePresence>
          </>
        )}
      </Item>
    </Link>
  );
};

export function LocaleConnectionListItem({ name }: { name: string }) {
  return (
    <LanguageItem>
      <ConnectionItemName>{name}</ConnectionItemName>
    </LanguageItem>
  );
}

export function CountryContinentListItem({
  name,
  collapsed,
  onClick,
}: {
  name: string;
  collapsed?: boolean;
  onClick?: MouseEventHandler;
}) {
  return (
    <CountryContinentItem onClick={onClick} $collapsed={collapsed}>
      <ConnectionItemName style={{ cursor: 'pointer' }}>{name}</ConnectionItemName>
      <IconChevronDown />
    </CountryContinentItem>
  );
}

export function CountryConnectionListItem({ name }: { name: string }) {
  return (
    <CountryContinentItem>
      <ConnectionItemName style={{ paddingLeft: 18 }}>{name}</ConnectionItemName>
    </CountryContinentItem>
  );
}

export function AssistantConnectionListItem({
  id,
  name,
  status,
  reference,
  languageId,
}: {
  id: string;
  name: string;
  status: ContentItemStatus | undefined;
  reference?: string | undefined;
  languageId?: string | undefined;
}) {
  const [hover, setHover] = useState(false);
  const links = useLinks();
  const transition = { ease: 'easeOut', duration: 0.2 };

  const href = languageId ? links.library.assistants.assistant.href : '#';
  const as = languageId ? links.library.assistants.assistant.as(id, languageId) : '#';

  return (
    <Link href={href} as={as}>
      <AssistantItem onHoverStart={() => setHover(true)} onHoverEnd={() => setHover(false)}>
        <ContentStatusWrapper animate={{ x: hover ? -30 : 0 }} transition={transition}>
          <StatusTag noTooltip status={status} size="sm" />
        </ContentStatusWrapper>
        <ConnectionItemName animate={{ x: hover ? -30 : 0 }} transition={transition}>
          {name}
        </ConnectionItemName>
        <AnimatePresence>
          {!hover && reference ? <Reference animate={{ opacity: hover ? 0 : 1 }}>{reference}</Reference> : null}
          {hover && (
            <Actions
              exit={{ opacity: 0, x: 32 }}
              initial={{ x: 32, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={transition}>
              <NewTabLinkButton href={languageId ? links.library.assistants.assistant.as(id, languageId) : '#'} />
            </Actions>
          )}
        </AnimatePresence>
      </AssistantItem>
    </Link>
  );
}
