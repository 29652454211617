import styled, { css } from 'styled-components';

export const FeedbackContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 16px;
`;

export const FeedbackTitle = styled.span<{ positive?: boolean }>`
  background-color: ${props => (props.positive ? '#E5F4F2' : '#FFF9E6')};
  color: ${props => (props.positive ? '#1A2F52' : '#483434')};
  border-radius: 5px;
  font-size: 16px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: ${props => (props.positive ? 'positive' : 'negative')};

  svg path {
    fill: ${props => (props.positive ? '#1A2F52' : '#483434')};
  }

  svg {
    height: 12px;
    margin-right: 4px;
  }
`;

export const Total = styled.p`
  color: var(--color-N500);
  font-size: 14px;
  width: 100%;
  display: inline-block;
  grid-area: total;
`;

export const Feedback = styled.p<{ positive?: boolean }>`
  background-color: ${props => (props.positive ? '#E5F4F2' : '#FFF9E6')};
  color: ${props => (props.positive ? '#1A2F52' : '#483434')};
  border-radius: 5px;
  font-size: 16px;
  padding: 4px 8px;
  display: block;

  svg path {
    fill: ${props => (props.positive ? '#1A2F52' : '#483434')};
  }

  svg {
    height: 12px;
    margin-right: 4px;
  }
`;

export const FeedbackGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  margin: 32px auto;
  max-width: 960px;
  width: 100%;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
`;

export const NavTitle = styled.div`
  font-size: 14px;
  color: var(--color-N600);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NavItem = styled.li<{ selected?: boolean }>`
  display: grid;
  grid-template-columns: 24px 1fr auto;
  align-items: center;
  padding: 8px;
  margin: 8px;
  border-radius: 5px;
  font-weight: 500;
  gap: 4px;

  > div:first-child {
    background-color: #fff;
  }

  &:hover {
    cursor: pointer;
    background: #e6e9ff;
  }

  ${props =>
    props.selected &&
    css`
      background: #e6e9ff;
    `};
`;

export const Count = styled.div`
  font-size: 14px;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
